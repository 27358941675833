
//Rutas

import admin_routes from "@/router/routes/clients/admin/routes";


import camanchaca_routes from "@/router/routes/clients/camanchaca/routes";
import fiordo_routes from "@/router/routes/clients/fiordo/routes";
import blumar_routes from "@/router/routes/clients/blumar/routes";
import sanlorenzo_routes from "@/router/routes/clients/sanlorenzo/routes";
import klabin_routes from "@/router/routes/clients/klabin/routes";
import cmp_routes from "@/router/routes/clients/cmp/routes";
import canias_routes from "@/router/routes/clients/canias/routes";
import molymet_routes from "@/router/routes/clients/molymet/routes";
import pf_routes from "@/router/routes/clients/pf/routes";
import sapdemo_routes from "@/router/routes/clients/sapdemo/routes";
import sqm_routes from "@/router/routes/clients/sqm/routes";
import nestle_routes from "@/router/routes/clients/nestle/routes";


import vuexy_routes from "@/router/routes/vuexy/routes";


//Navigation Menu
import admin_menu from "@/navigation/vertical/clients/admin/menu.js";
import camanchaca_menu from "@/navigation/vertical/clients/camanchaca/menu.js";
import fiordo_menu from "@/navigation/vertical/clients/fiordo/menu.js";
import blumar_menu from "@/navigation/vertical/clients/blumar/menu.js";
import sanlorenzo_menu from "@/navigation/vertical/clients/sanlorenzo/menu.js";
import klabin_menu from "@/navigation/vertical/clients/klabin/menu.js";
import cmp_menu from "@/navigation/vertical/clients/cmp/menu.js";
import canias_menu from "@/navigation/vertical/clients/canias/menu.js";
import molymet_menu from "@/navigation/vertical/clients/molymet/menu.js";
import pf_menu from "@/navigation/vertical/clients/pf/menu.js";
import sapdemo_menu from "@/navigation/vertical/clients/sapdemo/menu.js";
import sqm_menu from "@/navigation/vertical/clients/sqm/menu.js";
import nestle_menu from "@/navigation/vertical/clients/nestle/menu.js";


import vuexy_menu from "@/navigation/vertical/vuexy/menu.js";


//Api Services

import ApiServices from "@/services/apiServices";

import AdminApiServices from "@/services/clients/AdminApiServices";
import SclApiServices from "@/services/clients/SclApiServices";
import CamanchacaApiServices from "@/services/clients/CamanchacaApiServices";
import BlumarApiServices from "@/services/clients/BlumarApiServices";
import FiordoApiServices from "@/services/clients/FiordoApiServices";
import CMPApiServices from "@/services/clients/CMPApiServices";
import CaniasApiServices from "@/services/clients/CaniasApiServices";
import MolymetApiServices from "@/services/clients/MolymetApiServices";
import PfApiServices from "@/services/clients/PfApiServices";
import SapDemoApiServices from  "@/services/clients/SapDemoApiServices";
import SqmApiServices from  "@/services/clients/SqmApiServices";
import NestleApiServices from  "@/services/clients/NestleApiServices";


import KlabinApiServices from "@/services/clients/KlabinApiServices";


export default class MultitenantService {

  
    constructor() {

        let clients = 
        {

            admin : 
            {
                routes : admin_routes,
                menuItems :admin_menu,
                apiService: new AdminApiServices() 
            },            

            demo2 : 
            {
                routes : camanchaca_routes,
                menuItems :camanchaca_menu,
                apiService: new CamanchacaApiServices() 
            },

            /*
            camanchaca : 
            {
                routes : camanchaca_routes,
                menuItems :camanchaca_menu,
                apiService: new CamanchacaApiServices() 
            },

            "camanchaca-app" : 
            {
                routes : camanchaca_app_routes,
                menuItems :camanchaca_app_menu,
                apiService: new CamanchacaAppApiServices() 
            },
*/

            klabin : 
            {
                routes: klabin_routes,
                menuItems: klabin_menu,
                apiService: new KlabinApiServices()
            },

            fiordoaustral : 
            {
                routes: fiordo_routes,
                menuItems: fiordo_menu,
                apiService: new FiordoApiServices()
            },

            blumar: 
            {
                routes: blumar_routes,
                menuItems: blumar_menu,
                apiService: new BlumarApiServices()
            },

            csl: 
            {
                routes: sanlorenzo_routes,
                menuItems: sanlorenzo_menu,
                apiService: new SclApiServices()
            },


            cmp: 
            {
                routes: cmp_routes,
                menuItems: cmp_menu,
                apiService: new CMPApiServices()
            },            

            canias: 
            {
                routes: canias_routes,
                menuItems: canias_menu,
                apiService: new CaniasApiServices()
            },
            molymet:
            {
                routes: molymet_routes,
                menuItems: molymet_menu,
                apiService: new MolymetApiServices()
            },

            pf:
            {
                routes: pf_routes,
                menuItems: pf_menu,
                apiService: new PfApiServices()
            },

            sapdemo:
            {
                routes: sapdemo_routes,
                menuItems: sapdemo_menu,
                apiService: new SapDemoApiServices()
            },

            sqm:
            {
                routes: sqm_routes,
                menuItems: sqm_menu,
                apiService: new SqmApiServices()
            },
            
 
            nestle:
            {
                routes: nestle_routes,
                menuItems: nestle_menu,
                apiService: new NestleApiServices()
            },

 
            vuexy: {
                routes: vuexy_routes,
                menuItems: vuexy_menu,
                apiService: new ApiServices()
            }

        }
      
          
        //this.host = window.location.host
        //this.subdomain = this.host.split('.')[0]   

        this.tenantName = this.getTenantName();

        this.client = clients[this.tenantName];

        if(this.client === undefined)
        {
            throw new Error("No hay un manejador para el tenant: " + this.tenantName);
        }

        this.routes = this.client.routes;
        this.menuItems = this.client.menuItems;
        this.apiService = this.client.apiService;
    }


    getTenantName(defaultClient = "demo2")
    {
        let host = window.location.host

        if(!host){
            return defaultClient;
        }

        let parts = host.split('.');

        if(parts.length <= 1){
            return defaultClient;
        }

        let subdomain = parts[0];
        
        return subdomain.toLowerCase();

    }



  
  
}